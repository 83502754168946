<template>
  <div class="prize-page">
    <yd-infinitescroll v-if="prize_list.length>0" :callback="getDrawList" ref="infinitescroll">
      <div class="prize-list" slot="list">
        <div class="prize-item" v-for="(item,index) in prize_list" :key="index">
          <div class="list-time">{{item.create_time|formatDateTime}}</div>
          <div class="list-main df fdr" @click="seaLottery ? detailPrize(item.id) : ''">
            <img class="prize-img" :src="item.item_info.item_img" alt="">
            <div class="prize-info">
              <div class="title txt-over">{{item.item_info.item_name}}</div>
              <div class="txt-over">{{item.item_info.item_desc}}</div>
              <div class="is-change">
                <p>{{item.is_exchange ? '已领取' : '未领取'}}</p>
              </div>
              <div v-if="item.item_info.receive_method && !item.is_exchange" class="is-change receive">
                <p @click="selectAddress(item.id, item.item_info.item_type)">立即领取 > ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip">-没有更多了-</span>
    </yd-infinitescroll>
    <!-- 空视图 -->
    <div class="empty-box" v-else-if="is_request&&prize_list.length===0">
      <Empty tip="奖品列表空空如也～"></Empty>
    </div>
  </div>
</template>

<script>
import {getDrawList, receiveAward, getDrawLogHaiyuansu} from "@/services/my";

import Empty from '@/components/empty/Empty'
import Test1 from '@/assets/img_home_fangjian01@3x.png';
import { customAccount } from "@/utils/custom";

export default {
  name: "RafflePrize",
  components: {
    Empty
  },
  data() {
    return {
      page: 1,
      listRows: 10,
      prize_list: [],
      is_request: 0,
      seaLottery: false,
      seaType: 0,  // 海元素抽奖类型
      turn_id: ''
    }
  },
  created() {
    this.seaType = this.$route.query.type || 0
    this.turn_id = this.$route.query.id
  },
  mounted() {
    this.getDrawList()
    this.isSeaLottery()
  },
  methods: {
    /**
     * 幸运大转盘-我的奖品
     * @returns {Promise<void>}
     */
    async getDrawList() {
      try {
        let params = {}
        this.seaType 
        ? params = {
          type: this.seaType,
          page: this.page,
          listRows: this.listRows,
          lottery_id: this.turn_id
        } 
        : params = {
          page: this.page,
          listRows: this.listRows,
        };
        const res = this.seaType ? await getDrawLogHaiyuansu(params) : await getDrawList(params);
        this.is_request = 1;
        if (res.code === 0) {
          const data = res.data;
          this.prize_list = [...this.prize_list, ...data.data];
          this.page++;
          if (this.prize_list.length === data.total) {
            /* 所有数据加载完毕 */
            this.$refs.infinitescroll.$emit('ydui.infinitescroll.loadedDone');
            return;
          }

          /* 单次请求数据完毕 */
          this.$refs.infinitescroll.$emit('ydui.infinitescroll.finishLoad');

        }
      } catch (e) {
        console.log(e);
      }
    },

    // 跳到地址页面
    selectAddress(item_id, item_type) {
      if (item_type) {
       if ( !this.seaLottery ) {
          this.receiveAward(item_id)
       } else {
          this.$router.push({
            name: 'receive_award',
            query: {
              name: 'receive_award',
              item_id: item_id
            }
          });
       }
      } else {
        this.$router.push({
          name: 'address',
          query: {
            name: 'turntable',
            id: item_id
          }
        });
      }

    },

    async receiveAward(item_id) {
      try {
        let params = {
          draw_log_id: item_id || 0
        }
        const res = await receiveAward(params)
        if (res.code === 0) {
          this.$dialog.toast({
            mes: "奖品领取成功，请注意查收",
            timeout: 1500
          });
          this.$router.go(-1);
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: 'error',
            timeout: 1500
          });
        }
      } catch(e) {}
    },

    //  是否是海元素抽奖商城
    isSeaLottery() {
      if ( customAccount('VUE_APP_SEA_LOTTERY') ) {
        this.seaLottery = true
        console.log('12', this.seaLottery)
      }
    },

    detailPrize(item_id) {
      this.$router.push({
        name: 'receive_award',
        query: {
          name: 'receive_award',
          item_id: item_id
        }
      });
    }
  },
}
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.prize-page {
  background: @color-white;
  height: 100%;

  .yd-list-loading {
    background: @color-white;
  }

  .yd-list-donetip {
    background: @color-white;
    .sc(0.28rem, @color-light-grey);
  }

  .prize-list {
    padding: 0.3rem;
    background: @color-white;

    .prize-item {
      margin-bottom: 0.4rem;

      .list-time {
        .sc(0.24rem, @color-dark-grey);
        line-height: 0.34rem;
      }

      .list-main {
        height: 2.2rem;
        background: @color-white;
        margin-top: 0.2rem;
        box-shadow: 0 0.06rem 0.4rem 0 rgba(0, 0, 0, 0.05);
        border-radius: 0.08rem;

        .prize-img {
          border-top-left-radius: 0.08rem;
          border-bottom-left-radius: 0.08rem;
          .wh(2.2rem, 2.2rem);
        }

        .prize-info {
          .wh(4.7rem, 100%);
          .sc(0.24rem, @color-light-grey);
          padding: 0.24rem 0 0.24rem 0.2rem;
          position: relative;

          .title {
            .sc(0.28rem, @color-dark-grey);
            line-height: 0.4rem;
          }
          
          .is-change {
            position: absolute;
            bottom: 0.3rem;
          }

          .receive {
            right: 0.2rem;
            color: #11BCF5
          }
        }
      }
    }

  }

  /* 列表为空*/

  .empty-box {
    background: @color-white;
    height: 100vh;
  }
}

</style>
