var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "prize-page" },
    [
      _vm.prize_list.length > 0
        ? _c(
            "yd-infinitescroll",
            { ref: "infinitescroll", attrs: { callback: _vm.getDrawList } },
            [
              _c(
                "div",
                {
                  staticClass: "prize-list",
                  attrs: { slot: "list" },
                  slot: "list",
                },
                _vm._l(_vm.prize_list, function (item, index) {
                  return _c("div", { key: index, staticClass: "prize-item" }, [
                    _c("div", { staticClass: "list-time" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatDateTime")(item.create_time))
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "list-main df fdr",
                        on: {
                          click: function ($event) {
                            _vm.seaLottery ? _vm.detailPrize(item.id) : ""
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "prize-img",
                          attrs: { src: item.item_info.item_img, alt: "" },
                        }),
                        _c("div", { staticClass: "prize-info" }, [
                          _c("div", { staticClass: "title txt-over" }, [
                            _vm._v(_vm._s(item.item_info.item_name)),
                          ]),
                          _c("div", { staticClass: "txt-over" }, [
                            _vm._v(_vm._s(item.item_info.item_desc)),
                          ]),
                          _c("div", { staticClass: "is-change" }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(item.is_exchange ? "已领取" : "未领取")
                              ),
                            ]),
                          ]),
                          item.item_info.receive_method && !item.is_exchange
                            ? _c("div", { staticClass: "is-change receive" }, [
                                _c(
                                  "p",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectAddress(
                                          item.id,
                                          item.item_info.item_type
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("立即领取 > >")]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              ),
              _c("span", { attrs: { slot: "doneTip" }, slot: "doneTip" }, [
                _vm._v("-没有更多了-"),
              ]),
            ]
          )
        : _vm.is_request && _vm.prize_list.length === 0
        ? _c(
            "div",
            { staticClass: "empty-box" },
            [_c("Empty", { attrs: { tip: "奖品列表空空如也～" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }